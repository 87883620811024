import React from "react";
import MobNavbar from "./MobNavBar";
import DesctopNavbar from "./DesctopNavBar";
import LangSwitch from "./LangSwitch";
import logo from "../../src/assets/logo192.png";
import MediaQuery from "react-responsive";

export default function Header(props) {
    return (
        <div className="header">
            <div className="container header__wrapper">
                <div className="header__logo">
                    <img src={logo} alt="" />
                </div>
                <MediaQuery maxWidth={768}>
                    <LangSwitch changeLang={props.changeLang} />
                    <MobNavbar text={props.text} lang={props.lang} />
                </MediaQuery>
                <MediaQuery minWidth={769}>
                    <DesctopNavbar text={props.text} lang={props.lang} />
                    <LangSwitch changeLang={props.changeLang} />
                </MediaQuery>
            </div>
        </div>
    );
}

import React, { useState } from "react";

const FastCall = (props) => {
    const [showButton, setShowBtn] = useState("");
    window.addEventListener("scroll", function () {
        if (this.pageYOffset > 160) {
            setShowBtn("show");
        } else {
            setShowBtn("");
        }
    });

    return (
        <a href="tel:+380994225939" className={`fast-call ${showButton}`}>
            <span>{props.text[props.lang].contactsCall}</span>
        </a>
    );
};

export default FastCall;

import React, { useState, useRef } from "react";
import ServiceCard from "./ServiceCard";

const Services = (props) => {
    const [isShowAll, setIsShowAll] = useState(false);
    const btnText = !isShowAll
        ? props.text[props.lang].servicesBtnTextMore
        : props.text[props.lang].servicesBtnTextClose;
    const nodeRef = useRef(null);

    return (
        <div className="services" id="services">
            <div className="container services__wrapper">
                <h2>{props.text[props.lang].servicesTitle}</h2>
                <h3>
                    {props.text[props.lang].servicesSubtitle} :
                    <ul>
                        <li>{props.text[props.lang].servicesFirst}</li>
                        <li>{props.text[props.lang].servicesSecond}</li>
                        <li>{props.text[props.lang].servicesThird}</li>
                        <li>{props.text[props.lang].servicesFour}</li>
                    </ul>
                </h3>
                <div className="services__cards-block">
                    <ServiceCard
                        title={props.text[props.lang].service1}
                        subtitle={props.text[props.lang].serviceSubtitle1}
                        price={500 + props.text[props.lang].servicePay}
                    />
                    <ServiceCard
                        title={props.text[props.lang].service2}
                        subtitle={props.text[props.lang].serviceSubtitle2}
                        price={350 + props.text[props.lang].servicePay}
                    />
                    <ServiceCard
                        title={props.text[props.lang].service3}
                        subtitle={props.text[props.lang].serviceSubtitle3}
                        price={800 + props.text[props.lang].servicePay}
                    />
                    <ServiceCard
                        title={props.text[props.lang].service4}
                        subtitle={props.text[props.lang].serviceSubtitle4}
                        price={
                            props.text[props.lang].servicePayPref +
                            50 +
                            props.text[props.lang].servicePay
                        }
                    />
                </div>
                <div
                    ref={nodeRef}
                    className="services__cards-block services__show"
                    style={
                        isShowAll
                            ? {
                                  height: nodeRef.current.scrollHeight,
                              }
                            : { height: "0px" }
                    }
                >
                    <ServiceCard
                        title={props.text[props.lang].service5}
                        subtitle={props.text[props.lang].serviceSubtitle5}
                        price={500 + props.text[props.lang].servicePay}
                    />
                    <ServiceCard
                        title={props.text[props.lang].service6}
                        subtitle={props.text[props.lang].serviceSubtitle6}
                        price={450 + props.text[props.lang].servicePay}
                    />
                    <ServiceCard
                        title={props.text[props.lang].service7}
                        subtitle={props.text[props.lang].serviceSubtitle7}
                        price={450 + props.text[props.lang].servicePay}
                    />
                    <ServiceCard
                        title={props.text[props.lang].service8}
                        subtitle={props.text[props.lang].serviceSubtitle8}
                        price={150 + props.text[props.lang].servicePay}
                    />
                </div>
                <button
                    className="services__show-btn"
                    onClick={() => {
                        setIsShowAll(!isShowAll);
                    }}
                >
                    {btnText}
                </button>
            </div>
        </div>
    );
};

export default Services;

import React from "react";

const ServiceCard = (props) => {
    return (
        <div className="service-card">
            <div className="service-card__wrapper">
                <h3>
                    <b>{props.title}</b>
                </h3>
                <p>{props.subtitle}</p>
                <span>
                    <b>{props.price}</b>
                </span>
            </div>
        </div>
    );
};

export default ServiceCard;

import "./App.scss";
import React, { useState } from "react";
import Header from "./components/Header";
import MainBanner from "./components/MainBanner";
import Services from "./components/Services";
import Masters from "./components/Masters";
import Place from "./components/Place";
import Contacts from "./components/Contacts";
import FastCall from "./components/FastCall";

function App() {
    const [lang, setLeng] = useState("ua");

    const text = {
        ua: {
            homeLink: "Головна",
            servicesLink: "Послуги",
            masterLink: "Майстри",
            placeLink: "Гості",
            contactsLink: "Контакти",
            bannerTitle: "Забудьте про кепки після стрижок",
            bannerSubtitle: "Отримайте бажане",
            bannerRegistration: "Реєстрація",
            servicesTitle: "Отримайте задоволення від якісно виконаних послуг",
            servicesSubtitle: "Такі послуги ви можете отримати у нас",
            servicesFirst: "Стрижки",
            servicesSecond: "Гоління",
            servicesThird: "Оформлення бороди",
            servicesFour: "Видалення волоссям воском",
            servicesBtnTextMore: "Показати ще",
            servicesBtnTextClose: "Приховати",
            mastersTitle: "Майстри",
            mastersSubtitle:
                "Кожен майстер у нас індивідуальний, але в одному вони схожі – це велика любов до своєї роботи, а отже, якість на висоті. Знайдемо підхід до кожного.",
            mastersNameFirst: "Барбер Анатолій",
            mastersNameSecond: "Барбер Ксюша",
            placeTitle: "Наші гості",
            placeSubtitle:
                "Кожен гість, який приходить в GalanT - важливий. У нашому барбершопі майстри приділяють весь час і увагу нашим гостям, допомагають визначитися із зачіскою та стилем,та рекомендуємо ту зачіску, яка підійде найкраще конкретно вам.",
            contactsAdressTitle: "Адреса",
            contactsCity: "м. Одеса",
            contactsAdress: "Фонтанська дорога, 69/2",
            contactsWithoutDayOff: "Без вихідних",
            contactsTime: "Графік роботи",
            contactsWithUs: "Давайте з нами",
            contactsCopiright: "Копірайт",
            contactsCall: "Дзвінок",
            service1: "Стрижка",
            service2: "Оформлення бороди",
            service3: "Стрижка + оформлення бороди",
            service4: "Корекція воском",
            service5: "Дитяча стрижка",
            service6: "Королівське гоління бороди",
            service7: "Королівське гоління голови",
            service8: "Укладання волосся",
            serviceSubtitle1:
                "Зробимо стрижку за вашим бажанням, або допоможемо з вибором стрижки під ваш стиль",
            serviceSubtitle2:
                "Оформлення бороди будь-якої довжини. Зробимо вашу бороду доглянутою та охайною, контури рівними, а враження незабутніми",
            serviceSubtitle3:
                "Підберемо для вашого образу ідеальну стрижку та бороду",
            serviceSubtitle4:
                "Допоможе видалити небажанне волосся, та зробить процес відростання більш тривалим",
            serviceSubtitle5:
                "До 13 років (включно). Підберемо, та зробимо гарну стрижку для наших молодших відвідувачів",
            serviceSubtitle6:
                "Гоління бороди - розпаюємо шкіру, наносимо піну для гоління, та прибираємо все небажане лезом",
            serviceSubtitle7:
                "Гоління Голови - розпаюємо шкіру, наносимо піну для гоління, та прибираємо все небажане волосся лезом",
            serviceSubtitle8:
                "Поспішаєте на свято а на голова біда? Наші майстри допоможуть с вирішенням цієї проблеми! Укладання волосся, та фіксація за допомогою професіональної косметики - це швидкий та дуже дієвий спосіб виглядати на всі 100 відсотків",
            servicePay: " грн",
            servicePayPref: " від ",
        },
        en: {
            homeLink: "Home",
            servicesLink: "Services",
            masterLink: "Masters",
            placeLink: "Guests",
            contactsLink: "Contacts",
            bannerTitle: "Forget about caps after haircuts",
            bannerSubtitle: "Get what you want",
            bannerRegistration: "Registration",
            servicesTitle: "Enjoy quality services",
            servicesSubtitle: "These are the services you can get with us",
            servicesFirst: "Haircuts",
            servicesSecond: "Shaving",
            servicesThird: "Beard styling",
            servicesFour: "Hair removal with wax",
            servicesBtnTextMore: "Show more",
            servicesBtnTextClose: "Hide",
            mastersTitle: "Masters",
            mastersSubtitle:
                "Each master with us is individual, but they are similar in one thing - a great love for their work, so the quality is top-notch. We will find an approach to each one.",
            mastersNameFirst: "Barber Anatoliy",
            mastersNameSecond: "Barber Ksusha",
            placeTitle: "Our guests",
            placeSubtitle:
                "Every guest who comes to GalanT is important. In our barbershop, the masters devote all their time and attention to our guests, help to determine with a haircut and style, and recommend the hairstyle that will suit you best.",
            contactsAdressTitle: "Address",
            contactsCity: "Odessa",
            contactsAdress: "Fontanska Doroga, 69/2",
            contactsWithoutDayOff: "No days off",
            contactsTime: "Working hours",
            contactsWithUs: "Get in touch with us",
            contactsCopiright: "Copyright",
            contactsCall: "Call",
            service1: "Haircut",
            service2: "Beard styling",
            service3: "Haircut + beard styling",
            service4: "Wax correction",
            service5: "Children's haircut",
            service6: "Royal beard shaving",
            service7: "Royal head shaving",
            service8: "Hair styling",
            serviceSubtitle1:
                "We will do the haircut according to your desire, or help you choose a haircut that suits your style",
            serviceSubtitle2:
                "Trimming a beard of any length. We will make your beard neat and tidy, with even contours, and the impression unforgettable",
            serviceSubtitle3:
                "We will find the perfect haircut and beard for your look",
            serviceSubtitle4:
                "Helps remove unwanted hair and makes the regrowth process longer",
            serviceSubtitle5:
                "Up to 13 years old (inclusive). We will choose and make a nice haircut for our younger visitors",
            serviceSubtitle6:
                "Beard shaving - we open the pores, apply shaving foam, and remove everything unwanted with a razor",
            serviceSubtitle7:
                "Head shaving - we open the pores, apply shaving foam, and remove all unwanted hair with a razor",
            serviceSubtitle8:
                "Hurry to a celebration, but having a bad hair day? Our masters will help solve this problem! Hairstyling and fixing with professional cosmetics is a quick and very effective way to look your best.",
            servicePay: " UAH",
            servicePayPref: " from ",
        },
    };

    return (
        <div className="App">
            <Header text={text} lang={lang} changeLang={setLeng} />
            <MainBanner text={text} lang={lang} />
            <Services text={text} lang={lang} />
            <Masters text={text} lang={lang} />
            <Place text={text} lang={lang} />
            <Contacts text={text} lang={lang} />
            <FastCall text={text} lang={lang} />
        </div>
    );
}

export default App;

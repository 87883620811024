import React, { useState } from "react";

const DesctopNavbar = (props) => {
    return (
        <div className="header__desc-menu">
            <ul>
                <li>
                    <a href="#main-banner">
                        <span>{props.text[props.lang].homeLink}</span>
                    </a>
                </li>
                <li>
                    <a href="#services">
                        <span>{props.text[props.lang].servicesLink}</span>
                    </a>
                </li>
                <li>
                    <a href="#master">
                        <span>{props.text[props.lang].masterLink}</span>
                    </a>
                </li>
                <li>
                    <a href="#place">
                        <span>{props.text[props.lang].placeLink}</span>
                    </a>
                </li>
                <li>
                    <a href="#contacts">
                        <span>{props.text[props.lang].contactsLink}</span>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default DesctopNavbar;

import React from "react";

const MainBanner = (props) => {
    return (
        <div className="banner" id="main-banner">
            <div className="container banner__wrapper">
                <h1>{props.text[props.lang].bannerTitle}</h1>
                <h3>{props.text[props.lang].bannerSubtitle}</h3>
                <a
                    href="https://n810841.alteg.io/?fbclid=PAAaYMO-xti2GbTtR319bN8_PP0PfRfJZOozf-OK6j0jpCBdH37VLpfDhegck"
                    target="_blank"
                    rel="noreferrer"
                >
                    <span>{props.text[props.lang].bannerRegistration}</span>
                </a>
            </div>
        </div>
    );
};

export default MainBanner;

import React from "react";

const Masters = (props) => {
    return (
        <div className="masters" id="master">
            <div className="container masters__wrapper">
                <h2>{props.text[props.lang].mastersTitle}</h2>
                <h3>{props.text[props.lang].mastersSubtitle}</h3>
                <div className="masters__cards">
                    <div className="masters__card">
                        <div className="masters__photo masters__photo--first"></div>
                        <p>{props.text[props.lang].mastersNameFirst}</p>
                    </div>
                    <div className="masters__card">
                        <div className="masters__photo masters__photo--second"></div>
                        <p>{props.text[props.lang].mastersNameSecond}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Masters;

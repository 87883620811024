import React, { useState } from "react";

const MobNavbar = (props) => {
    // to change burger classes
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
    const [menu_class, setMenuClass] = useState("menu hidden");
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    // toggle burger menu change
    const updateMenu = () => {
        if (!isMenuClicked) {
            setBurgerClass("burger-bar clicked");
            setMenuClass("menu visible");
        } else {
            setBurgerClass("burger-bar unclicked");
            setMenuClass("menu hidden");
        }
        setIsMenuClicked(!isMenuClicked);
    };

    return (
        <div className="header__main-menu">
            <nav>
                <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                </div>
            </nav>

            <div className={menu_class}>
                <div className="header__mob-menu">
                    <ul>
                        <li>
                            <a
                                href="#main-banner"
                                onClick={() => {
                                    setBurgerClass("burger-bar unclicked");
                                    setMenuClass("menu hidden");
                                    setIsMenuClicked(!isMenuClicked);
                                }}
                            >
                                <span>{props.text[props.lang].homeLink}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#services"
                                onClick={() => {
                                    setBurgerClass("burger-bar unclicked");
                                    setMenuClass("menu hidden");
                                    setIsMenuClicked(!isMenuClicked);
                                }}
                            >
                                <span>
                                    {props.text[props.lang].servicesLink}
                                </span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#master"
                                onClick={() => {
                                    setBurgerClass("burger-bar unclicked");
                                    setMenuClass("menu hidden");
                                    setIsMenuClicked(!isMenuClicked);
                                }}
                            >
                                <span>{props.text[props.lang].masterLink}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#place"
                                onClick={() => {
                                    setBurgerClass("burger-bar unclicked");
                                    setMenuClass("menu hidden");
                                    setIsMenuClicked(!isMenuClicked);
                                }}
                            >
                                <span>{props.text[props.lang].placeLink}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="#contacts"
                                onClick={() => {
                                    setBurgerClass("burger-bar unclicked");
                                    setMenuClass("menu hidden");
                                    setIsMenuClicked(!isMenuClicked);
                                }}
                            >
                                <span>
                                    {props.text[props.lang].contactsLink}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default MobNavbar;

import React, { useState } from "react";

const LangSwitch = (props) => {
    return (
        <div className="header__links">
            <div className="lang-switch">
                <a onClick={() => props.changeLang("ua")}>UA</a>
                <span> | </span>
                <a onClick={() => props.changeLang("en")}>EN</a>
            </div>
        </div>
    );
};

export default LangSwitch;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import logo from "../../src/assets/logo192.png";

const Place = (props) => {
    // const images = [
    //     { url: "../assets/img/IMG_3147.JPG" },
    //     { url: "../assets/img/IMG_3148.JPG" },
    //     { url: "../assets/img/IMG_3158.JPG" },
    //     { url: "../assets/img/IMG_3160.JPG" },
    //     { url: "../assets/img/IMG_3161.JPG" },
    //     { url: "../assets/img/IMG_3162.JPG" },
    //     { url: "../assets/img/IMG_3173.JPG" },
    //     { url: "../assets/img/IMG_3192.JPG" },
    //     { url: "../assets/img/IMG_3193.JPG" },
    //     { url: "../assets/img/IMG_3201.JPG" },
    //     { url: "../assets/img/IMG_3202.JPG" },
    //     { url: "../assets/img/IMG_3204.JPG" },
    //     { url: "../assets/img/IMG_3206.JPG" },
    //     { url: "../assets/img/IMG_3207.JPG" },
    //     { url: "../assets/img/IMG_3208.JPG" },
    //     { url: "../assets/img/IMG_3241.JPG" },
    //     { url: "../assets/img/IMG_3243.JPG" },
    //     { url: "../assets/img/IMG_3244.JPG" },
    //     { url: "../assets/img/IMG_3245.JPG" },
    //     { url: "../assets/img/IMG_3246.JPG" },
    //     { url: "../assets/img/IMG_3247.JPG" },
    //     { url: "../assets/img/IMG_3249.JPG" },
    //     { url: "../assets/img/IMG_3253.JPG" },
    //     { url: "../assets/img/IMG_3255.JPG" },
    //     { url: "../assets/img/IMG_3256.JPG" },
    //     { url: "../assets/img/IMG_3265.JPG" },
    //     { url: "../assets/img/IMG_3271.JPG" },
    //     { url: "../assets/img/IMG_3276.JPG" },
    //     { url: "../assets/img/IMG_3284.JPG" },
    //     { url: "../assets/img/IMG_3286.JPG" },
    //     { url: "../assets/img/IMG_3299.JPG" },
    //     { url: "../assets/img/IMG_3301.JPG" },
    //     { url: "../assets/img/IMG_3302.JPG" },
    //     { url: "../assets/img/IMG_3303.JPG" },
    //     { url: "../assets/img/IMG_3324.JPG" },
    //     { url: "../assets/img/IMG_3325.JPG" },
    //     { url: "../assets/img/IMG_3326.JPG" },
    //     { url: "../assets/img/IMG_3329.JPG" },
    // ];

    return (
        <div className="place" id="place">
            <div className="container place__wrapper">
                <h2>{props.text[props.lang].placeTitle}</h2>
                <h3>{props.text[props.lang].placeSubtitle}</h3>
                <Swiper
                    loop={true}
                    navigation={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={50}
                    slidesPerView={1}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Navigation, Autoplay]}
                >
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3147.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3148.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3158.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3160.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3161.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3162.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3173.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3192.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3193.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3201.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3202.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3204.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3206.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3207.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3208.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3241.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3243.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3244.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3245.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3246.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3247.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3249.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3253.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3255.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3256.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3265.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3271.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3276.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3284.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3286.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3299.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3301.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3302.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3303.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3324.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3325.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3326.JPG")}
                            alt=""
                        />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img
                            src={require("../assets/img/IMG_3329.JPG")}
                            alt=""
                        />
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </div>
    );
};

export default Place;

import React from "react";
import instIcon from "../../src/assets/img/inst.svg";

const Contacts = (props) => {
    return (
        <div className="contacts" id="contacts">
            <div className="container contacts__wrapper">
                <div className="contacts__adress">
                    <p>{props.text[props.lang].contactsAdressTitle}</p>
                    <p>{props.text[props.lang].contactsCity}</p>
                    <p>{props.text[props.lang].contactsAdress}</p>
                </div>
                <div className="contacts__work-time">
                    <p>{props.text[props.lang].contactsTime}:</p>
                    <p>10:00 - 20:00</p>
                    <p>{props.text[props.lang].contactsWithoutDayOff}</p>
                </div>
                <div className="contacts__social">
                    <p>{props.text[props.lang].contactsWithUs}:</p>
                    <div>
                        <a
                            href="https://instagram.com/galant__barbershop_?igshid=MzMyNGUyNmU2YQ=="
                            target="_blank"
                            rel="noreferrer"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="100"
                                height="100"
                                viewBox="0,0,256,256"
                            >
                                <g
                                    fill="#ffffff"
                                    fill-rule="nonzero"
                                    stroke="none"
                                    stroke-width="1"
                                    stroke-linecap="butt"
                                    stroke-linejoin="miter"
                                    stroke-miterlimit="10"
                                    stroke-dasharray=""
                                    stroke-dashoffset="0"
                                    font-family="none"
                                    font-weight="none"
                                    font-size="none"
                                    text-anchor="none"
                                >
                                    <g transform="scale(8,8)">
                                        <path d="M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z"></path>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
                {/* <div className="contacts__copyrite">
                    <p>{props.text[props.lang].contactsCopiright}</p>
                </div> */}
            </div>
        </div>
    );
};

export default Contacts;
